import {isPalm} from './helperLib.js';
import BackgroundFocus from '../Components/BackgroundFocus/background-focus.js';

const lazyBgImgSelector = '.js-lazybg';
const lazyContentImgSelector = '.js-lazyimg';

export const lazyImageLoader = (function() {
	const lazyLoadedClass = 'js-lazy-is-loaded';
	const bgImages = document.querySelectorAll(lazyBgImgSelector);
	const contentImages = document.querySelectorAll(lazyContentImgSelector);
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const mediaQueryList = window.matchMedia('print');

	const observerOptions = {
    root:null,
    rootMargin:"0px",
    threshold: 0.1
	};

	/**
	 * splits a srcset string in an array with src and media query
	 *
	 * @param srcset string
	 * @return imageSources []
	 */
	const getSrcsetList = function(srcset) {
		const imageSources = [];
		if (srcset.length > 0) {
			srcset.forEach(function (imageItem) {
				imageSources.push({
					src: imageItem.substr(0, imageItem.indexOf(' ')),
					mediaquery: imageItem.substr(imageItem.indexOf(' ') + 1)
				});
			});
		}
		return imageSources;
	};

	/**
	 * loads the background image by determining the matching image and setting its url as background-image
	 *
	 * @param image
	 */
	const loadBgImage = function(image) {
		if(image.classList.contains(lazyLoadedClass)) {
			return;
		}
		if(isPalm() && image.classList.contains('no-mobile-bg-image')) {
			return;
		}
		if(image.dataset.hasOwnProperty('srcset')) {
			const matchingImage = getMatchingBgImage(image.dataset.srcset.split(', '));
      image.style.backgroundImage = `url(${matchingImage})`;
			image.classList.add(lazyLoadedClass);
		}

		if(image.dataset.hasOwnProperty('focus')) {
			try {
				BackgroundFocus.observe(image, JSON.parse(image.dataset.focus));
			} catch (e) {
				// do nothing if there is an error in the json string
			}
		}
	};

	/**
	 * loads content image by setting the srcset attribute and the size attribute to current width of image element
	 *
	 * @param image
	 */
    const loadContentImage = function(image) {
      if(image.classList.contains(lazyLoadedClass)) {
        return;
      }
      const slotWidth = image.offsetWidth;
      /* TODO: check slotWidth why it could be 0 */
      if(image.hasAttribute('srcset') && slotWidth !== 0) {
        image.setAttribute('sizes', slotWidth + 'px');
      }
      if(image.dataset.hasOwnProperty('srcset')) {
        const contentImageSrcsets = getSrcsetList(image.dataset.srcset.split(', '));
        image.setAttribute('srcset', image.dataset.srcset);
        if(slotWidth !== 0) {
          image.setAttribute('sizes', slotWidth + 'px');
        }
        image.classList.add(lazyLoadedClass);
      }
    };

	/**
	 * iterate over given srcset to find matching size
	 *
	 * @param srcset array
	 * @returns {*}
	 */
	const getMatchingBgImage = function(srcset) {
		let matchingImage = null;
		if(srcset.length > 0) {
			let imageSources = [];
			srcset.forEach(function(imageItem) {
				imageSources.push({
					src: imageItem.substr(0, imageItem.indexOf(' ')),
					mediaquery: imageItem.substr(imageItem.indexOf(' ') + 1)
				});
			});
			for(let i = 0; i < imageSources.length; i++) {
				const imageSource = imageSources[i];
				if(imageSource.mediaquery.length > 0) {
					const mediaquery = window.matchMedia(imageSource.mediaquery);
					if(mediaquery.matches) {
						matchingImage = imageSource.src;
						break;
					}
				} else {
					matchingImage = imageSource.src;
					break;
				}
			}
		}
		return matchingImage;
	};

	// intersection observer handler for background images
	const handleBgImagesIntersection = function(entries, observer) {
		entries.forEach(function(entry) {
			if(entry.intersectionRatio > 0) {
				loadBgImage(entry.target)
			}
		})
	};

	// intersection observer handler for content images
	const handleContentImagesIntersection = function(entries, observer) {
		entries.forEach(function(entry) {
			if(entry.intersectionRatio > 0) {
				loadContentImage(entry.target)
			}
		})
	};

	const bgImagesObserver = new IntersectionObserver(handleBgImagesIntersection, observerOptions);
	const contentImagesObserver = new IntersectionObserver(handleContentImagesIntersection, observerOptions);

	function init() {

		bgImages.forEach(function(img) {
			bgImagesObserver.observe(img);
		});

		contentImages.forEach(function(img) {
			contentImagesObserver.observe(img);
		});

		window.addEventListener('resize', function() {
			const loadedImages = document.querySelectorAll('.' + lazyLoadedClass);
			loadedImages.forEach(function(img) {
				img.classList.remove(lazyLoadedClass);
			});
		});

		const loadAll = function() {
			bgImages.forEach(function(img) {
				loadBgImage(img)
			});

			contentImages.forEach(function(img) {
				loadContentImage(img)
			});
		};

		if(!isSafari) {
			window.onbeforeprint = function() {
				loadAll();
			};
		} else {
			// Safari doesn't support the onbeforeprint event
			mediaQueryList.addListener(function(mql) {
				if(mql.matches) {
					loadAll();
				}
			});
		}
	}

	return {
		init: init,
		loadBgImage: loadBgImage,
		loadContentImage: loadContentImage
	}

})();

if(document.querySelector(lazyBgImgSelector + ',' + lazyContentImgSelector) !== null) {
	lazyImageLoader.init();
}
